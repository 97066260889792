// Training List

$cell-width: 104px;
$action-width: 30px;

.training {
  $self: &;

  &-list {
    display: flex;
    flex-direction: column;

    .warning {
      color: $warning;
    }

    .danger {
      color: $error;
    }

    .success {
      color: $success;
    }

    .is-disabled {
      color: $light-gray-3;
      pointer-events: none;
    }

    .delete {
      &:hover {
        color: $error;
      }
    }

    .duplicate {
      &:hover {
        color: $gray-1;
      }
    }

    .empty-state {
      padding-bottom: 14px;

      &.bp3 {
        &-icon svg {
          width: 48px;
        }

        &-non-ideal-state-visual {
          margin-bottom: 10px;
        }

        &-heading {
          margin-bottom: 10px;
        }
      }
    }
  }

  &-listHeader {
    display: none;
    background: $light-gray-5;
    padding: 8px 160px 8px 10px;
    font-size: 14px;
    // text-transform: uppercase;
    font-weight: $semibold;
    color: darken($gray-1, 10%);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    border-bottom: 1px solid $light-gray-4;
    border-radius: 8px 8px 0 0;
    #{$self}-cellTitle {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
    }
    #{$self}-cell {
      flex: 0 0 $cell-width;
      padding: 0 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 0;
    }
  }

  &-itemContent {
    padding: 12px 150px 12px 0;
    display: flex;
    align-items: center;
    min-height: 72px;
  }

  &-item {
    position: relative;
    border-bottom: 1px solid $light-gray4;
    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: lighten($light-gray5, 1%);
      text-decoration: none;
    }

    .title {
      padding-right: 2px;
    }

    .web {
      color: $primary;
      display: inline-block;
      font-size: 10px;
      position: relative;
      top: -8px;
      left: 0;
    }
  }

  &-itemLink {
    display: block;
    flex: 1 1 auto;
    &:hover {
      text-decoration: none;
    }
  }

  &-itemTitle {
    flex: 1 1 auto;
    color: $black-color;
    font-size: 15px;
    font-weight: $bold;
  }

  &-itemCell {
    font-weight: $medium;
    display: none;
    color: rgba($black-color, 0.9);
    text-align: center;
    font-size: 14px;
  }

  &-itemThumbnail {
    flex: 0 0 36px;
    height: 36px;
    margin-right: 12px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  &-itemActions {
    right: 0px;
    @include vertical-align(absolute);
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    .bp3-spinner {
      padding: 0;
      width: $action-width;
    }
  }

  &-itemAction {
    color: $gray3;
    cursor: pointer;
    width: $action-width;
    text-align: center;
    svg {
      width: 15px;
      height: 15px;
    }
    &:hover {
      color: $gray-1;
    }
  }

  &-actions {
    margin-top: 46px;
    text-align: center;
    padding-bottom: 4px;
    .button-dropdown {
      justify-content: center;
    }
  }

  // Media queries

  @include mq($until: phone) {
    &-itemTitle {
      font-size: 14px;
      font-weight: $semibold;
    }
    &-itemContent {
      min-height: 64px;
    }
  }

  @include mq(tablet) {
    &-list {
      border: 1px solid $light-gray-4;
      border-radius: 8px;
    }

    &-item {
      padding: 0 10px 0 16px;
    }

    &-itemActions {
      right: 6px;
    }
  }

  @include mq(desktop) {
    &-itemContent {
      flex-direction: row;
      padding-right: 0;
    }

    &-listHeader {
      display: flex;
    }

    &-itemCell {
      display: block;
      text-align: center;
      padding: 0 10px;
      flex: 0 0 $cell-width;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-itemActions {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex: 0 0 auto;
      transform: none;
    }

    &-list {
      .delete {
        &:hover {
          color: $error;
        }
      }
    }
  }

  @include mq(desktop-medium) {
    &-itemCell,
    &-listHeader #{$self}-cell {
      flex-basis: $cell-width;
    }
  }

  @include mq(1360px) {
    &-itemCell,
    &-listHeader #{$self}-cell {
      flex-basis: 130px;
    }
  }

  @include mq(desktop-wide) {
    &-itemCell,
    &-listHeader #{$self}-cell {
      flex-basis: 160px;
    }
  }

  // Delete editor access in small screens
  @media screen and (max-width: #{$mobile-threshold}) and (orientation: portrait) {
    &-itemActions {
      .editor-access {
        display: none;
      }
    }
  }
}
