// Overview page

.overview {
  &-page {
    padding-bottom: 16px;
  }

  @include mq(phone) {
    &-page {
      .select-sort {
        justify-content: flex-end;
      }
    }
  }
}
