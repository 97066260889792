// Blueprint theme

a {
  color: $primary;
}

h2.bp3-heading {
  font-size: 20px;
  font-weight: $bold;
  line-height: 1.2;
  @include mq(tablet) {
    font-size: 26px;
  }
}

// bp3 styles

.bp3 {
  &-non-ideal-state {
    padding: 20px;
  }

  // toast

  &-toast {
    font-weight: $semibold;
    outline: none;

    .bp3-button {
      border: none !important;
      outline: none;
      min-height: 30px;
      min-width: 30px;
    }
  }

  // menu

  &-menu {
    min-width: 120px;
  }

  // menu item

  &-menu-item {
    &.bp3-intent-primary {
      &.bp3-active {
        background: $primary;
      }
    }
    svg,
    svg g,
    svg path {
      fill: $gray-1;
      color: $gray-1;
    }
  }

  // tab

  &-tab {
    outline: none;
    &:focus {
      outline: none;
    }
    &-indicator {
      &-wrapper {
        outline: none;
      }
    }
    &[aria-selected='true'] {
      font-weight: $semibold;
    }
    &-list {
      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  // card

  &-card {
    border-radius: $border-radius;
    box-shadow: none;
    border: 1px solid $light-gray-3;
    padding: 30px;
  }

  // alert

  &-alert-body {
    margin-bottom: 10px;
  }

  // popover

  &-popover2 {
    &-content {
      .bp3-button {
        display: block;
        border: none;
      }
    }
  }

  // checkbox

  &-control {
    input:focus {
      ~ .bp3-control-indicator {
        outline: none;
      }
    }

    &:hover {
      input:checked {
        ~ .bp3-control-indicator {
          background: darken($primary, 6%);
        }
      }
    }
    input:checked {
      ~ .bp3-control-indicator {
        background: $primary;
      }
    }
  }

  &-checkbox {
    &:focus {
      outline: none;
    }
    input:checked {
      ~ .bp3-control-indicator {
        &:before {
          content: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4L1 3L3.5 5.5L9 0L10 1L3.5 7.5L0 4Z' fill='white'/%3E%3C/svg%3E%0A");
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: none !important;
        }
      }
    }
  }

  // switch

  &-control {
    &.bp3-switch {
      &:hover {
        input:checked {
          ~ .bp3-control-indicator {
            background: darken($primary, 6%);
            box-shadow: none;
          }
        }
      }
    }

    input {
      &:focus {
        outline: none;
        box-shadow: none;
        ~ .bp3-control-indicator {
          outline: none;
          box-shadow: none;
          border-color: $light-gray-3;
        }
      }
    }
    &-indicator {
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  // Input

  &-input {
    &-group {
      .bp3-input-action {
        top: 50%;
        transform: translateY(-50%);
        padding-right: 8px;
      }
    }
  }

  &-form-group {
    .bp3-form-helper-text {
      font-weight: $semibold;
      padding-left: 2px;
    }
    &.bp3-intent-danger {
      .bp3-form-helper-text {
        color: $error;
      }
    }
    &.bp3-intent-primary {
      .bp3-form-helper-text {
        color: $primary;
      }
    }
  }

  &-intent-success {
    .bp3-input {
      border-color: $success;
    }
    .bp3-icon {
      color: $success;
    }
  }

  // Tag

  &-tag {
    border-radius: $border-radius;
    font-weight: $semibold;
  }

  // Inputs
}

:focus {
  outline: none;
}
