@import 'lib/ui-components/globals/styles/settings.scss';

// Dialogs

.dialog {
  width: 406px;
  background: #fff;
  position: relative;

  p {
    margin-bottom: 8px;
  }

  .loading-text {
    text-align: center;
    color: $gray-1;
    font-size: 14px;
    margin-top: 10px;
  }

  .bp3 {
    &-spinner {
      background-color: #fff;
      padding: 0;
    }

    &-button-text {
      text-align: center;
    }

    &-dialog-header {
      box-shadow: none;
      padding: 12px 10px 10px 20px;
      border-bottom: 1px solid $light-gray-4;
      .bp3-icon {
        color: $primary;
        position: relative;
        top: -1px;
      }
    }

    &-input-group .bp3-input {
      font-size: 15px;
    }

    &-dialog-body {
      min-height: 40px;
    }

    &-dialog-footer {
      margin: 0 15px 0 15px;
    }

    &-dialog-footer-actions {
      justify-content: space-between;
      .bp3-button {
        margin: 0;
        flex: 1 1 50%;
        margin: 0 5px;
        box-shadow: none;
      }
    }

    &-dialog-close-button {
      box-shadow: none;
      border: none;
      width: 40px;
      height: 40px;
      position: relative;
      top: -2px;
      right: -2px;
      &:hover {
        background: transparent;
        svg {
          color: $black-color;
        }
      }
    }

    &-icon-small-cross svg {
      width: 30px;
      height: 30px;
      color: $gray-1;
    }
  }

  &-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 12px 12px 12px;
    text-align: right;
    display: flex;
    justify-content: space-between;
    .bp3 {
      &-button {
        flex: 0 0 calc(50% - 5px);
      }
    }
  }

  &-danger {
    .bp3 {
      &-spinner.bp3-intent-primary &-spinner-head {
        stroke: $error;
      }

      &-dialog-header {
        .bp3-icon {
          color: $error;
        }
      }
    }
  }

  &-success {
    .bp3 {
      &-spinner.bp3-intent-primary &-spinner-head {
        stroke: $success;
      }

      &-dialog-header {
        .bp3-icon {
          color: $success;
        }
      }
    }
  }
}
