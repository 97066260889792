// Editable element

$element-height: 32px;

.editable {
  &-container,
  &-linkContainer {
    .bp3 {
      &-spinner .bp3-spinner-head {
        stroke: $primary;
      }

      &-button {
        width: 32px;
        height: 32px;
        min-height: 0;
        border-color: $light-gray-5;
        background: $light-gray-5;
        border-radius: $border-radius - 2px;
        margin-left: 4px;
        animation: appear-top 0.2s ease;
        svg {
          color: $primary;
        }
      }
      &-button.bp3-disabled {
        background-color: $light-gray-5;
      }
    }
  }

  // editable container

  &-container {
    position: relative;
    padding: 0;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid $light-gray-4;
    padding-bottom: 4px;

    .button {
      z-index: 2;
    }

    .bp3 {
      &-input {
        border: none;
        border-radius: 4px;
        position: relative;
        top: 0;
        line-height: 1.2;
        margin-top: 4px;
        font-size: 16px;
        color: $gray-1;
        padding: 12px 0 0 0;
        height: $element-height;
        animation: appear-top 0.3s ease;
        transition: all 0.2s ease-in-out;
        svg {
          color: $primary;
        }
      }
      &-input-action {
        @include vertical-align(absolute);
      }

      &-input-group {
        top: 0;
      }
    }

    .title {
      font-size: 40px;
    }
  }
}

.title-field {
  .bp3 {
    &-input {
      font-size: 26px;
      font-weight: 700;
      color: $black-color;
      padding: 7px 8px 7px 0;
    }
  }
}

// Editable element

.editable {
  &-element {
    .button-icon {
      min-width: 32px;
    }
    &.has-changed {
      .bp3-input {
        background-color: $light-gray-5;
        padding: 7px 8px;
        color: $gray-1;
        border-bottom: none;
        font-weight: normal;
        font-size: 15px;
      }
      .title-field .bp3-input {
        color: $gray-1;
        border-bottom: none;
        font-weight: normal;
        font-size: 15px;
      }
    }
  }
}

// Editable link

.editable {
  &-linkContainer {
    padding-bottom: 4px;
    margin-top: 4px;
    border-bottom: 1px solid $light-gray-4;
    .button-icon {
      min-width: 32px;
    }
  }

  &-link {
    .bp3 {
      &-input-group .bp3-input {
        margin-top: 0;
        background: $light-gray-5;
        border-bottom: none;
        font-weight: normal;
        font-size: 15px;
        border: none;
        height: $element-height;
        border-radius: 6px;
        animation: fade-in 0.3s ease-in-out;
      }

      &-input-left-container {
        z-index: 100;
      }
    }
    .insetLabel {
      position: relative;
      top: 7px;
      margin-left: 10px;
      animation: fade-in 1.2s ease;
      font-size: 15px;
    }
  }

  &-linkBox {
    display: flex;
    align-items: center;
    height: $element-height;
  }

  &-singleLink {
    flex: 1 1 auto;
    color: $primary;
    font-size: 15px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;

    &:hover {
      color: darken($primary, 10%);
    }
  }
}
