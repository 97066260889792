@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// ChartBox

.chartBox {
  $self: &;
  display: block;
  flex: 0 1 100%;
  margin-bottom: 20px;
  max-width: 860px;
  border: 1px solid $light-gray-3;
  border-radius: $border-radius;
  overflow: hidden;
  background: #fff;

  &-header {
    display: flex;
    align-items: center;
    background: $light-gray-5;
    border-bottom: 1px solid $light-gray-4;
    padding: 10px 12px;
  }

  &-title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: $semibold;
    flex: 1 1 auto;
    color: $gray-1;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  &-value {
    flex: 0 0 auto;
    font-weight: bold;
    font-size: 18px;
  }

  &-content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 40px);
    padding: 10px 0;
  }

  &-tabs {
    display: flex;
    flex-direction: column;
  }

  &-tab {
    line-height: 20px;
  }

  &-composition {
    #{$self}-title {
      padding-right: 94px;
    }
  }

  &-group {
    display: flex;
    flex-wrap: wrap;
    max-width: $layout-maxWidth;
  }

  .apexcharts {
    &-canvas {
      margin: 0 auto;
    }

    &-legend {
      margin: 0 auto;
    }

    &-legend-text {
      text-transform: capitalize;
    }

    &-legend-marker {
      margin: 0 6px 1px 0 !important;
    }
  }

  #chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bp3 {
    &-tab-indicator-wrapper {
      height: 30px !important;
      left: 2px;
    }

    &-indicator {
      bottom: -3px;
    }

    &-tab-list {
      padding: 6px 10px;
      position: absolute;
      top: -36px;
      right: 0;
      z-index: 2;
    }

    &-tab-panel {
      margin-top: 0;
      img {
        width: 100%;
      }
    }

    &-non-ideal-state {
      margin-bottom: 36px;
    }

    &-non-ideal-state-visual {
      margin-bottom: 10px;
    }
  }

  > * {
    width: 100%;
  }

  @include mq(tablet) {
    flex: 0 1 calc(50% - 10px);

    #chart {
      min-height: 315px;
    }

    &-title {
      font-size: 14px;
    }

    &-content {
      > * {
        min-height: 315px;
      }
    }
    &-group {
      .chartBox {
        margin-right: 20px;
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
  @include mq(desktop) {
    #chart {
      min-height: 315px;
    }
  }
}
