@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Layouts

// App layout

.outer-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header {
    flex: 0 0 auto;
    // z-index: 100;
  }

  @include mq(desktop-wide) {
    // Adjust scrollbar
    .header {
      padding-right: 15px;
    }
  }
}

.main-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 26px 0 0 0;
  position: relative;

  .footer {
    margin-top: auto;
  }

  .wrapper {
    width: 100%;
  }

  @include mq(desktop-wide) {
    .wrapper {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Auth layout

.auth {
  &-container {
    background: $light-gray-5;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .header {
      flex: 0 0 auto;
    }

    .bp3 {
      &-input-group .bp3-input {
        font-size: 14px;
      }

      &-button {
        margin-top: 18px;
      }

      &-intent-danger .bp3-input {
        border-color: $error;
      }
    }
  }

  &-content {
    flex: 1 1 auto;
    padding: 40px 0;
  }

  &-card {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    padding: 26px;
  }

  &-title {
    margin: 0 0 10px 0;
    font-weight: $bold;
  }

  &-subtitle {
    font-weight: 400;
    margin-bottom: 16px;
    font-size: 14px;
    color: $gray-1;
  }

  &-link {
    display: block;
    text-align: center;
    padding: 16px 0 0 0;
    color: $primary;
  }

  @include mq($until: 846px) {
    &-container {
      .header-project {
        display: block;
      }
    }
  }

  @include mq(tablet) {
    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 60px;
    }

    &-card {
      // margin: 140px auto;
      margin-top: -40px;
      padding: 36px;
    }
  }
}
