@import 'lib/ui-components/globals/styles/settings.scss';

// Banner

.banner {
  width: 100%;
  background: $primary;
  color: #fff;
  z-index: 10;
  padding: 6px 0;
  .bp3-icon {
    margin-right: 6px;
    svg {
      width: 14px;
    }
  }

  &.has-animation {
    animation: appear-top 0.2s ease;
  }

  &-top {
    z-index: 11;
    font-size: 14px;
  }

  &-error {
    background: $error;
  }

  .wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 0 16px;
  }

  &-text {
    font-size: 13px;
    line-height: 1.1;
  }

  &-link {
    font-weight: bold;
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
    color: #fff;
    cursor: pointer;
    outline: none;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}
