@import 'lib/ui-components/globals/styles/settings.scss';

// Animation

@keyframes appear-bottom {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-16px);
  }
}

// Copy clipboard button

.button-icon {
  &.activated {
    background: $primary !important;
    position: relative;
    transition: all 0.3s ease-in-out;
    svg {
      fill: #fff;
    }
    &:after {
      content: 'Saved!';
      position: absolute;
      top: -1px;
      right: 0;
      text-align: right;
      animation: appear-bottom 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      color: $primary;
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

.copy-clipboard-button {
  svg,
  svg g {
    fill: $primary;
  }
  &.activated {
    &:after {
      content: 'Text copied!';
    }
  }
}
