// Base

// Tipos

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;900&display=swap');

// Global

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: $font-base;
}

body {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  padding: 0 16px;
  max-width: $layout-maxWidth;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Content
iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    outline: none;
  }
}

.link {
  color: $primary;
  font-weight: $semibold;
  &:hover {
    color: darken($primary, 5%);
  }
}

.show-in-mobile {
  display: block;
  @include mq(tablet) {
    display: none;
  }
}

.show-in-desktop {
  display: none;
  @include mq(tablet) {
    display: block;
  }
}
