@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Panels

.dataPanel {
  $self: &;
  border: 1px solid $light-gray-3;
  background: #fff;
  padding: 20px;
  display: inline-flex;
  flex-direction: column;
  border-radius: $border-radius;
  margin-bottom: 20px;
  min-width: 0;
  flex: 1 1 100%;
  position: relative;

  &-group {
    display: flex;
    flex-wrap: wrap;
  }

  &-label {
    text-transform: uppercase;
    font-size: 12px;
    color: $gray-1;
    font-weight: normal;
    margin-bottom: 10px;
  }

  &-value {
    margin-top: auto;
    justify-self: flex-end;
    font-size: 28px;
    font-weight: $bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  &-indicator {
    font-weight: $bold;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  &-caption {
    font-weight: normal;
    margin-left: 6px;
    font-size: 11px;
    color: $gray-3;
    margin-top: 2px;
    font-style: italic;
  }

  &-marker {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid transparent;
  }

  &-text {
    margin-left: 1px;
  }

  .indicator-success {
    color: $success;
    .marker {
      border-bottom-color: $success;
    }
  }

  .indicator-error {
    color: $error;
    .marker {
      border-bottom-color: $error;
      transform: rotate(180deg);
    }
  }
  @include mq(phone) {
    flex: 1 1 calc(33.3% - 14px);
    margin-right: $space-l;
    &:nth-child(3n),
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
  @include mq(tablet) {
    &-value {
      font-size: 32px;
    }

    &-label {
      font-size: 14px;
    }
  }
  @include mq(desktop) {
    flex: 1 1 calc(20% - 22px);
    &:nth-child(3n) {
      margin-right: $space-l;
    }

    &-label {
      min-height: 36px;
    }

    &-value {
      font-size: 40px;
    }
  }

  @include mq(desktop) {
    flex: 1 1 calc(20% - 22px);
    &:nth-child(3n) {
      margin-right: $space-l;
    }
    &-group {
      flex-wrap: nowrap;
    }
  }
}
