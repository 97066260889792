// Details page

.details {
  &-page {
    border-radius: 0;
    position: relative;
    animation: appear-top 0.3s ease;

    .spinner-overlay {
      margin-top: 0;
    }
  }

  &-intro {
    border-bottom: 1px solid $light-gray-4;
    margin-bottom: 13px;
    padding-top: 10px;

    &-simple {
      border-bottom: none;
    }
  }

  &-label {
    text-transform: uppercase;
    font-weight: normal;
    color: $gray-3;
    font-size: 13px;
  }

  &-field {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  &-fields {
    width: 100%;
  }

  &-fieldsCol {
    flex: 0 0 50%;
    padding-right: 24px;
    min-width: 0;
  }

  &-main {
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
    min-width: 0;
  }

  &-thumbnail {
    margin-bottom: 10px;
    background: $light-gray2;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    min-width: 140px;
    min-height: 140px;
    margin-right: 22px;
    border: 1px solid $light-gray-4;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include centered(absolute);
    }
    .bp3-tag {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  &-actions {
    flex: 0 0 10%;
    display: flex;
    flex-direction: column;
    text-align: center;
    &.bp3-skeleton {
      margin-left: 20px;
    }
  }

  &-linkInput {
    .bp3-input {
      color: $gray-1;
      font-size: 16px;
    }
  }

  &-caption {
    opacity: 0.7;
    font-size: 12px;
  }

  &-switch {
    text-transform: uppercase;
    color: $gray-3;
    margin-bottom: 20px;
    &.bp3-control {
      font-size: 12px;
      font-weight: $semibold;
    }
  }

  &-linkActive {
    .editableLink {
      color: $primary;
      &:hover {
        color: darken($primary, 10%);
      }
    }
  }

  &-password {
    .switch {
      margin-bottom: 0;
    }
  }

  // Media queries

  @include mq($from: phone, $until: desktop) {
    &-intro {
      display: flex;
      align-items: flex-start;
      padding: 20px 0;
    }
  }

  @include mq($until: phone) {
    &-actions {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 20px 0 12px;
    }

    &-thumbnail {
      margin-right: 0;
    }

    &-main {
      display: block;
    }

    &-fieldsCol {
      padding-right: 0;
    }

    &-switch {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include mq($until: phone) {
    &-page {
      .action-button {
        border-width: 2px;
      }
    }
  }

  @include mq($until: tablet) {
    &-thumbnail {
      max-width: 200px;
    }

    &-page {
      .action-button {
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 4px;
        font-size: 12px;

        .bp3-icon {
          margin-right: 6px;
        }
      }
    }
  }

  @include mq(tablet) {
    &-intro {
      display: flex;
      align-items: flex-start;
      padding: 20px 0;
    }

    &-thumbnail {
      flex: 0 0 20%;
      margin: 0 24px 0 0;
      max-width: 260px;
    }
  }

  @include mq(desktop) {
    &-field {
      flex: 0 0 50%;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &-fields {
      display: flex;
    }

    &-thumbnail {
      flex-basis: 220px;
    }
  }

  @include mq(desktop-medium) {
    &-actions {
      flex-basis: 210px;
    }

    &-page {
      .action-button {
        padding-right: 26px;
        padding-left: 26px;
      }
    }
  }

  // Delete editor access in small screens
  @media screen and (max-width: #{$mobile-threshold}) and (orientation: portrait) {
    &-page {
      .editor-access {
        display: none;
      }
    }
  }
}
