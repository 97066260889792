// Datepicker

// Override react-datepicker styles

.react-datepicker {
  border-color: $light-gray-3 !important;
  &__day--selected {
    background-color: $primary !important;
  }

  &__header {
    background: #fff !important;
    border-bottom-color: $light-gray-3 !important;
  }

  &__triangle {
    display: none;
  }

  &__navigation {
    outline: none;
  }

  &__day--keyboard-selected {
    background-color: darken($primary, 6%) !important;
  }

  &__day {
    outline: none;
  }
}
