// Videos

.video {
  &-wrapper {
    // margin-top: 36px;
    background: lighten($light-gray-5, 1%);
    border-top: 1px solid $light-gray-4;
    padding: 70px 24px;
    margin: 30px 0 0 0;
  }

  &-content {
    margin: 0 auto;
    max-width: 800px;
    position: relative;
    overflow: hidden;

    iframe {
      border: none;
      box-shadow: $elevation1;
      border-radius: 8px;
    }
  }
  &-title {
    text-align: center;
    margin-bottom: 36px;
  }

  @include mq($until: tablet) {
    &-title {
      margin-bottom: 26px;
    }
    &-content {
      padding-top: 52%;
      overflow: hidden;

      iframe {
        @include centered(absolute);
        width: 100%;
        height: 100%;
      }
    }
  }
}
