@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Buttons

// Bp styles override

.bp3 {
  &-button {
    outline-color: transparent;
    transition: all 0.3s ease;
    -webkit-tap-highlight-color: transparent;
    border-radius: $border-radius;
    font-weight: $semibold;
    min-height: 34px;
    min-width: 34px;

    &:active,
    &.bp3-active {
      background: $light-gray-5;
    }
    &.is-selected {
      border-color: $primary !important;
      svg,
      svg g,
      svg path {
        color: $primary;
        fill: $primary;
      }
    }

    &[class*='bp3-intent-'] {
      .bp3-button-spinner .bp3-spinner-head {
        stroke: $gray-1;
      }
    }

    &:not([class*='bp3-intent-']),
    &[class*='bp3-intent-'] {
      background-image: none;
      box-shadow: none;
      &:hover,
      &:active {
        box-shadow: none;
      }
      &.bp3-active {
        box-shadow: none;
      }
    }

    &.bp3-intent-primary {
      &:hover {
        background: darken($primary, 5%);
      }
      &:disabled,
      &.bp3-disabled {
        background: $light-gray-3;
        color: $gray-3;
      }
    }

    &:not([class*='bp3-intent-']) {
      border: 1px solid $light-gray-2;
      box-shadow: none;
      background: transparent;
      &:hover {
        box-shadow: none;
        background: $light-gray-5;
      }
    }
    &.bp3-minimal {
      outline-color: transparent;
      &:focus {
        outline: none;
      }
      &.bp3-intent-primary {
        color: $primary;
        background: #fff;
        border: 1px solid rgba($primary, 0.3);
        &:hover {
          background: rgba($primary, 0.03);
        }
        &:disabled {
          background: rgba($primary, 0.02);
        }
      }

      &.bp3-intent-danger {
        border: 1px solid $error;
        &:hover {
          background: rgba($error, 0.08);
        }
      }
    }
  }
}

.bp3-outlined {
  animation: fade-in $general-change;
}

// Button outlined

.button-outlined {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $light-gray-2;
  padding: 9px 7px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 4px;
  color: $black-color;
  font-weight: $semibold;
  &:hover {
    text-decoration: none;
    background: $light-gray-5;
    color: $black-color;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .bp3-icon {
    + span {
      padding-left: 4px;
    }
  }
  @include mq(tablet) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

// Button icon

.button-icon {
  width: 34px;
  height: 34px;
  svg {
    width: 20px;
    height: 20px;
  }
  svg,
  svg g {
    fill: lighten($black-color, 5%);
    color: lighten($black-color, 5%);
  }
}

// Button dropdown

.button-dropdown {
  display: flex;
  align-items: center;

  .button-icon {
    margin: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid transparent;
  }
  svg {
    transition: transform 0.3s ease-in-out;
  }

  .button-main {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .bp3-active {
    .button-icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .bp3-intent-primary,
  .bp3-intent-danger,
  .bp3-intent-warning,
  .bp3-intent-success {
    &:disabled {
      .bp3-spinner-head {
        stroke: #fff;
      }
      .bp3-spinner-track {
        stroke: rgba($black, 0.08);
      }
    }
    &.button-icon {
      border-left-color: rgba(#fff, 0.4);
    }

    svg,
    svg g {
      fill: #fff;
      color: #fff;
    }
  }

  .bp3-intent-primary {
    &:disabled {
      background: rgba($primary, 0.5);
    }
  }
}
