@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Spinner

.spinner {
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    // height: 100%;
    // min-height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 10;
    transition: background-color 0.3s ease;
    .bp3-spinner {
      @include centered(absolute);
      z-index: 10;
      border-radius: 50%;
      background: #fff;
    }
    &.is-opaque {
      background: #fff;
      z-index: 99;
    }

    &.is-full {
      position: fixed;
      height: 100vh;
    }
  }
  &-top {
    padding: 0 16px;
    height: 38px;
    position: absolute;
    top: -2px;
    right: 0;
    background: #fff;
    z-index: 10;
    justify-content: flex-end;
  }

  @include mq(desktop-wide) {
    &-top {
      padding-right: 0;
    }
  }
}
