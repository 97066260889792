// User settings

.settings {
  $self: &;
  &-page {
    max-width: 700px;
    margin: 0 auto;

    .spinner {
      display: inline-flex;
      padding-right: 20px;
      svg {
        width: 30px;
      }
    }

    &.admin-user {
      max-width: 1100px;
    }
  }

  &-form {
    .bp3 {
      &-input {
        height: 40px;
      }

      &-label {
        text-transform: uppercase;
        font-size: 12px;
        color: $gray-1;
        font-weight: $semibold;
      }

      &-button {
        height: 40px;
        padding-right: 20px;
        padding-left: 20px;
        box-shadow: none;
      }
    }
  }

  &-intro {
    border-bottom: 1px solid $light-gray-4;
    padding: 16px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .bp3-button {
      margin-top: 6px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &-title {
    font-weight: $bold;
    flex: 1 1 auto;
    margin: 6px 10px 0 0;
  }

  &-subtitle {
    text-transform: uppercase;
    font-size: 12px;
    color: $gray-1;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-col {
    .bp3-heading {
      margin-bottom: 20px;
    }
  }

  &-actions {
    text-align: right;
    padding: 20px 0;
  }

  &-datepicker {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    animation: appear-top $quick-change;
  }

  &-management {
    margin: 12px 0 0 0;

    .bp3 {
      &-switch {
        margin-bottom: 8px;
      }
    }
    .react-datepicker-wrapper {
      width: auto;
    }
  }

  &-dateInfo {
    color: $gray-1;
    margin-right: 20px;
  }

  &-dateInfo,
  &-datepicker .react-datepicker-wrapper {
    margin-bottom: 10px;
  }

  // Media queries

  @include mq($until: phone) {
    &-title {
      font-size: 20px;
    }
  }

  @include mq(tablet) {
    &-content {
      display: flex;
      flex-direction: row;
    }

    &-col {
      flex: 1 1 50%;
      padding: 0 20px 0 0;
      + #{$self}-col {
        padding: 0 0 0 20px;
      }
    }
  }
}
