@import 'lib/ui-components/globals/styles/settings.scss';

// Link Button

.link-button {
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius;
  color: $primary;
  border: 1px solid $light-gray-3;
  padding: 5px 10px 5px 8px;
  font-weight: $semibold;
  font-size: 14px;
  transition: all 0.3s ease;
  .bp3-icon {
    margin-right: 7px;
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }

  &:hover {
    text-decoration: none;
    background: $light-gray-5;
    color: $primary;
  }
}
