// Inputs

// Helper text

.helper-text {
  display: block;
  margin: 2px 0;
  &.bp3-intent {
    &-danger {
      color: $error;
    }
    &-primary {
      color: $primary;
    }
    &-success {
      color: $success;
    }
  }
}

// Checkbox icon check

.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
  background-image: url('../../icons/icon-check.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
