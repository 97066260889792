@import 'lib/ui-components/globals/styles/mixins.scss';

// User select

.select {
  $self: &;

  &-user {
    display: flex;
    align-items: center;
    margin-right: 4px;

    .select {
      margin: 0;
      width: 140px;
      display: block;

      .bp3-button {
        width: 100%;
        text-align: left;
        padding-right: 6px;
        border-radius: 8px 0 0 8px;
        min-height: 34px;
      }
    }

    .bp3 {
      &-button-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        flex: 1 1 auto;
        margin-right: 2px;
      }

      &-popover {
        width: 180px;
      }

      &-popover-content {
        padding: 4px;
        background: #fff;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 13px;
      }

      &-menu {
        padding: 4px 0 0 0;
      }

      &-input-action {
        position: relative;
      }

      &-input-action .bp3-button {
        right: 3px;
        margin: 1px 0 0 0;
        @include vertical-align(absolute);
        border: none;
        border-radius: 6px;
      }

      &-icon {
        transition: transform 0.3s ease-in-out;
      }

      &-popover-open .bp3-icon-caret-down {
        transform: rotate(180deg);
      }

      &-popover-target {
        width: 100%;
      }
    }
  }

  &-cancel {
    background: transparent;
    width: 34px;
    height: 34px;
    border-radius: 0 8px 8px 0;
    margin-left: -1px;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}
