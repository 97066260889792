// Dashboard

.dashboard {
  $self: &;
  &-layout {
    animation: appear-top 0.3s ease;
    padding-bottom: 16px;
  }

  &-title {
    margin: 0 0 20px;
  }

  @include mq($until: phone) {
    #{$self}-layout {
      #{$self}-title {
        padding: 10px 0;
      }
    }
  }
  @include mq(tablet) {
    &-title {
      margin-bottom: 26px;
    }
  }
}
