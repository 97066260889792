// Settings

// Colors

$gray-1: #5c7080;
$gray-3: #8a9ba8;
$light-gray-1: #ced9e0;
$light-gray-2: #d8e1e8;
$light-gray-3: #e1e8ed;
$light-gray-4: #ebf1f5;
$light-gray-5: #f5f8fa;
$primary: #0092ff;
$success: #3dcc91;
$warning: #f29d49;
$error: #f55656;
$error-dark: #db3737;
$black: #000;
$black-color: #182026;
$white: #fff;
$light-primary-5: lighten($primary, 0.6);

// Chart colors

$chart-blue-light: #48e3ff;
$chart-blue-dark: #3978ff;
$chart-purple: #989fff;
$chart-green: #2ee6d6;
$chart-pink: #f5498b;

// Fonts
$font-base: 16;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
// $black: 900;

$font: 'Inter', sans-serif;

$space-xs: 6px;
$space-s: 12px;
$space-m: 14px;
$space-l: 16px;
$space-xl: 26px;

$elevation1: 1px 1px 10px 0 rgba($black-color, 0.1);
$border-radius: 8px;

// Layout
$layout-maxWidth: 1460px;

$breakpoints: (
  phone-narrow: 460px,
  phone: 568px,
  tablet: 768px,
  desktop: 1000px,
  desktop-medium: 1180px,
  desktop-wide: 1500px,
);

// Transitions
$small-item-change: 0.3s ease;
$color-change: 0.3s;
$general-change: 0.3s ease-in-out;
$quick-change: 0.1s ease-in-out;
