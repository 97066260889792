// Switches

// Switch box

.switch {
  &-box {
    padding: 8px 10px;
    background: $light-gray-5;
    border-radius: 0 0 $border-radius $border-radius;
    .bp3 {
      &-control {
        margin: 0;
      }
    }
  }
}
