@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Select sort by

.select {
  $self: &;
  &-sort {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    select {
      outline: none;
      background: transparent;
      border: 1px solid $light-gray-4;
      box-shadow: none;
      padding-right: 30px;
      border-radius: 8px;
      &:hover {
        background: $light-gray-5;
        box-shadow: none;
      }
    }
    #{$self}-label {
      margin: 0 6px 0 0;
      font-weight: $semibold;
      color: darken($gray-1, 10%);
      font-size: 14px;
    }
  }
}
