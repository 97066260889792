// Animations

// Transitions
$small-item-change: 0.3s ease;
$color-change: 0.3s;
$general-change: 0.3s ease-in-out;
$quick-change: 0.1s ease-in-out;

// Keyframes
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear-top {
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nav-active-item {
  0% {
    opacity: 0;
    transform: scaleX(0);
    transform-origin: 0 0;
    width: 0;
    background-color: $primary;
  }

  100% {
    opacity: 1;
    transform: scaleX(100%);
    width: 100%;
    background-color: $primary;
    transform-origin: 0 0;
  }
}
