// Custom dialogs

.dialog {
  &-share {
    min-height: 410px;
    padding-bottom: 60px;
    position: relative;

    .bp3 {
      &-tab-panel {
        margin-top: 0;
      }
      &-dialog-body {
        margin: 10px 20px 0;
      }
      &-spinner {
        padding-top: 30%;
      }

      &-tab-indicator {
        bottom: -2px;
      }
    }
  }

  &-tabs {
    .bp3 {
      &-tab-list {
        border-bottom: 2px solid $light-gray-4;
      }

      &-tab {
        padding: 4px 10px;
      }
    }
  }

  &-qrBox {
    text-align: center;
    padding: 20px 0;
  }

  &-qrImage {
    width: 180px;
    margin: 0 auto;
  }

  &-embedText {
    border: 1px solid $light-gray-4;
    border-radius: $border-radius;
    padding: 12px 16px;
    min-height: 120px;
    font-size: 13px;
    line-height: 1.3;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  &-move {
    overflow: visible;
  }

  &-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    p {
      margin-right: 10px;
    }

    .bp3 {
      &-popover-content {
        // max-height: 200px;
        overflow-y: hidden;
      }

      &-button-text {
        text-align: left;
      }

      &-popover-wrapper,
      &-popover {
        width: 230px;
      }
      &-menu {
        overflow: auto;
        height: 180px;
      }
    }
  }
}
