// Layout

// adjust spinner overlay in Overview tab
// Make it not hidden by the header

.main-container {
  .spinner-overlay {
    margin-top: 20px;
  }
  .bp3-spinner {
    margin-top: -20px;
  }
}
