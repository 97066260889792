@import 'lib/ui-components/globals/styles/settings.scss';

// Action button

.action-button {
  padding: 7px 16px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: 1px solid $light-gray-3;
  border-radius: $border-radius;
  color: $black-color;
  font-size: 14px;
  font-weight: $semibold;
  min-height: 38px;
  margin: 0 0 4px 0;

  .bp3-button-text {
    color: $black-color;
  }
  .bp3-icon {
    color: $gray-1;
    margin-right: 12px;
  }

  &:hover {
    text-decoration: none;
    background: $light-gray-5;
    color: $primary;
    .bp3-icon {
      color: $primary;
    }
  }
  &.bp3-button {
    background-image: none;
    border-color: $light-gray-3;
    background-color: #fff;
    &:hover {
      text-decoration: none;
      background: $light-gray-5;
      color: $black-color;
      .bp3-icon,
      .bp3-button-text {
        color: $primary;
      }
    }
  }
  &.is-selected {
    border-color: $primary;
    color: $primary;
    .bp3-button-text,
    .bp3-icon {
      color: $primary;
    }
  }
  &.is-disabled {
    background: $light-gray-5;
    pointer-events: none;
    color: $gray-1;
    .bp3-button-text,
    .bp3-icon {
      color: $gray-3;
    }
  }
}
