@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Page heading

.page {
  &-heading {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    .link-button {
      margin-right: 10px;
    }
  }

  &-title {
    font-weight: $bold;
    flex: 1 1 auto;
    margin: 0;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    display: block;
    padding-right: 6px;

    .backButton {
      margin-bottom: 6px;
    }
  }

  @include mq(tablet) {
    &-title {
      font-size: 26px;
      padding: 0;
    }

    &-heading {
      padding-bottom: 26px;
    }
  }
}
