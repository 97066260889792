// Messages

.message {
  $self: &;
  &-confirmation {
    text-align: center;
    #{$self}-text {
      color: $gray-1;
      font-size: 14px;
    }
    #{$self}-icon {
      margin: 0 0 6px 0;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    &.status-success {
      svg {
        color: $success;
      }
    }
    &.status-primary {
      svg {
        color: $primary;
      }
    }
    &.status-error {
      svg {
        color: $error;
      }
    }
  }
}
