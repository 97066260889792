// Main nav

.nav {
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;

  &-group {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    height: auto;
  }

  .button-outlined {
    margin-right: 4px;
    height: 34px;
    min-width: 34px;
  }

  &-link {
    padding: 8px;
    border-radius: 0;
    margin-right: 4px;
    display: inline-flex;
    align-items: center;
    font-weight: $semibold;
    transition: all 0.1s ease;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -10px;
      left: 0;
    }
    &.bp3-button {
      border: none;
      box-shadow: none;
      &:hover {
        background: transparent;
      }
    }

    .bp3-icon {
      margin-right: 6px;
      transition: color 0.1s ease;
    }

    &:hover {
      text-decoration: none;
      background: transparent;
      color: $primary;
      .bp3-icon {
        color: $primary;
      }
    }
  }

  .is-active {
    color: $primary;
    position: relative;
    .bp3-icon {
      color: $primary;
    }
    &:after {
      animation: nav-active-item 0.2s ease forwards;
    }
  }

  @include mq($until: phone-narrow) {
    .bp3-popover-wrapper {
      max-width: 98px;
    }

    &-link {
      width: 30px;
    }
  }

  @include mq($until: phone) {
    .button-text {
      display: none;
    }

    &-link {
      padding: 8px;
      width: 34px;
      height: 34px;
      margin-right: 0;
    }
  }

  @include mq($until: 840px) {
    &-link {
      .bp3 {
        &-button-text {
          display: none;
        }
        &-icon {
          margin: 0;
        }
      }
    }
  }
}
