@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Header

.header {
  $self: &;
  background: #fff;
  border-bottom: 1px solid $light-gray-5;
  box-shadow: $elevation1;
  height: auto;
  z-index: 10;
  line-height: 1;
  padding: 0;
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
  }

  .banner-top {
    + .wrapper {
      height: auto;
    }
  }

  &-logoXl {
    display: none;
  }

  &-logoSm {
    display: block;
  }

  &-logo {
    display: block;
    width: 26px;
    margin: 0 6px 0 0;
    flex-shrink: 0;
  }

  &-project {
    font-size: 20px;
    font-weight: 300;
    margin-right: 12px;
    padding-left: 12px;
    border-left: 1px solid $light-gray-3;
    flex-shrink: 0;
  }

  // Media queries

  @include mq($until: phone) {
    > .wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }

    &-project {
      font-size: 16px;
    }
  }

  @include mq($until: tablet) {
    &-project {
      display: none;
      border-left: none;
      padding-left: 4px;
    }
  }

  @include mq(tablet) {
    &-logoXl {
      display: block;
    }

    &-logoSm {
      display: none;
    }

    &-logo {
      width: 100px;
      margin: 2px 12px 0 0;
    }
  }

  @include mq(desktop-wide) {
    .wrapper,
    .banner-top .wrapper {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
