@import 'lib/ui-components/globals/styles/settings.scss';
@import 'lib/ui-components/globals/styles/mixins.scss';

// Time selector

.time {
  $self: &;

  &-selector {
    display: flex;
    flex-wrap: wrap;
    margin: 6px 0 16px 0;
    width: 100%;

    &:focus {
      outline: none;
    }

    .radio {
      flex: 0 0 64px;
      outline: none;
      position: relative;
      z-index: 9;
      padding: 10px;
      text-align: center;
      font-weight: $semibold;
      min-width: 68px;
      border-radius: $border-radius;
      margin: 0 4px 4px 0;
      -webkit-tap-highlight-color: transparent;
      height: 40px;
      font-size: 12px;
      padding-right: 10px;
      padding-left: 10px;

      &:active {
        background: rgba($primary, 0.04);
      }
      &:hover {
        background: rgba($primary, 0.04);
      }

      &-input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;

        &:checked {
          + .radio-label {
            border: 2px solid $primary;
            color: darken($primary, 15%);
            box-shadow: inset 0px 0px 4px 1px rgba($primary, 0.6);
            &:after {
              box-shadow: inset 0px 0px 0px 2px $primary;
            }
          }
        }
      }

      &-label {
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        border: 2px solid $light-gray-3;
        z-index: 1;
        box-shadow: none;
        transition: all 0.3s ease;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 40px;
        &:after {
          content: '';
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          position: absolute;
          top: -1px;
          left: -1px;
          border-radius: 6px;
        }
      }
    }
  }

  @include mq(tablet) {
    display: inline-flex;

    &-selector {
      .radio {
        flex: 0 0 90px;
        font-size: 13px;
      }
    }
  }
}
