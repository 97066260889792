@import 'lib/ui-components/globals/styles/settings.scss';

// form elements

.bp3 {
  &-input {
    border: 1px solid $light-gray-2;
    box-shadow: none !important;
    border-radius: $border-radius;

    &-group {
      .bp3-input-action {
        top: 50%;
        transform: translateY(-50%);
        padding-right: 8px;
      }
    }
  }

  &-checkbox {
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
    }
    .bp3-control-indicator {
      border: 1px solid $light-gray-3;
      box-shadow: none;
      width: 22px;
      height: 22px;
      border-radius: 2px;
      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }

  &-form-group {
    &.bp3-intent-danger {
      .bp3-form-helper-text {
        color: $error;
        font-weight: $semibold;
        padding-left: 2px;
      }
    }
  }

  &-intent-danger {
    .bp3-input {
      border-color: $error;
    }
    .bp3-icon {
      color: $error;
    }
  }

  &-intent-primary {
    .bp3-input {
      border-color: $primary;
    }
    .bp3-icon {
      color: $primary;
    }
  }

  &-intent-success {
    .bp3-input {
      border-color: $success;
    }
    .bp3-icon {
      color: $success;
    }
  }
}

// Helper text

.helper-text {
  display: block;
  margin: 2px 0;
  &.bp3-intent {
    &-danger {
      color: $error;
    }
    &-primary {
      color: $primary;
    }
    &-success {
      color: $success;
    }
  }
}

.radio {
  display: inline-flex;
  align-items: center;
  input {
    margin: 0 6px 0 0;
    width: 16px;
    height: 16px;
  }
}
